body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Objective';
  font-weight: 400;
}

svg {
  box-sizing: content-box;
}

.h-100 {
  height: 100%;
}

button,
input {
  font-family: 'Objective';
}

body::-webkit-scrollbar,
div::-webkit-scrollbar,
textarea::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track {
  background-color: rgba(7, 5, 80, 0.1);
  border: 3px solid #fff;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-clip: padding-box;
  border: 3px solid rgba(0, 0, 0, 0);
  background-color: #b5adb0;
}

.ql-editor {
  font-family: Objective;
}

.ql-editor p {
  font-size: 16px;
}

.ql-editor h2 {
  font-size: 20px;
  font-weight: bold;
}

.ql-editor h1 {
  font-size: 24px;
  font-weight: bold;
}
input,
textarea {
  box-sizing: content-box;
}

@font-face {
  font-family: 'Right Grotesk';
  src: local('Right Grotesk'),
    url('res/fonts/RightGrotesk/RightGrotesk-WideMedium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Right Grotesk Bold';
  src: local('Right Grotesk'),
    url('res/fonts/RightGrotesk/RightGrotesk-SpatialBlack.otf')
      format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Objective';
  src: url('res/fonts/Objective/Objective-Thin.otf') format('opentype');
  font-display: swap;
  font-weight: 100 200;
}

@font-face {
  font-family: 'Objective';
  src: url('res/fonts/Objective/Objective-Light.otf') format('opentype');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Objective';
  src: url('res/fonts/Objective/Objective-Regular.otf') format('opentype');
  font-display: swap;
  font-weight: 400 500;
  /* 500 should be medium */
}

@font-face {
  font-family: 'Objective';
  src: url('res/fonts/Objective/Objective-Medium.otf') format('opentype');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Objective';
  src: url('res/fonts/Objective/Objective-Bold.otf') format('opentype');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Objective';
  src: url('res/fonts/Objective/Objective-ExtraBold.otf') format('opentype');
  font-display: swap;
  font-weight: 800 900;
}

@font-face {
  font-family: 'Objective Bold';
  src: url('res/fonts/Objective/Objective-Bold.otf') format('opentype');
  font-display: swap;
}

:root {
  --header-height: 96px;
  --checkmark-fill-color: #7ac142;
  --checkmark-arrow-color: #fff;
  --checkmark-arrow-thickness: 5;
}

.removeTransform {
  transform: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }

  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

.d-flex {
  display: flex;
}

.infinite-scroll-component__outerdiv {
  flex: 1;
}

.mt-8px {
  margin-top: 8px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-40px {
  margin-top: 40px;
}

.mt-42px {
  margin-top: 42px;
}

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.children {
  width: calc(50% - 10px);
  margin: 5px;
  background: #d8d8d8;
  flex: 1;
  height: 91px;
}

.children:first-child {
  flex: 0 0 calc(100% - 10px);
  /* border: 2px solid #44C0FF; */
}

.img-1 {
  width: 100%;
}

.checkmark {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  stroke: var(--checkmark-arrow-color);
  stroke-width: var(--checkmark-arrow-thickness);
  stroke-miterlimit: 10;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: var(--checkmark-arrow-thickness);
  stroke-miterlimit: 10;
  stroke: var(--checkmark-fill-color);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 100vh var(--checkmark-fill-color);
  }
}

.ql-custom-wrapper .ql-bubble .ql-tooltip {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.88);
  width: auto;
}

.ql-custom-wrapper .ql-bubble .ql-picker-label {
  border: 1px solid #303030;
  border-radius: 6px;
}

.ql-custom-wrapper .ql-bubble .ql-toolbar button {
  border: 1px solid #303030;
  border-radius: 6px;

  &:not(:first-child) {
    margin-left: 8px;
  }
}

.ql-custom-wrapper .ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  border-top: 6px solid rgba(0, 0, 0, 0.88);
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='10px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='10px']::before {
  content: '10px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='10px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='10px']::before {
  content: '10px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='12px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='12px']::before {
  content: '12px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='14px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='14px']::before {
  content: '14px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='16px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='16px']::before {
  content: '16px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='18px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='18px']::before {
  content: '18px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='20px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='20px']::before {
  content: '20px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='22px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='22px']::before {
  content: '22px';
}

.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-item[data-value='24px']::before,
.ql-custom-wrapper
  .ql-picker.ql-size
  .ql-picker-label[data-value='24px']::before {
  content: '24px';
}

.Toastify__toast-container {
  min-width: 320px;
  width: fit-content !important;
}

/* UATBanner.css */
.uat-banner {
  width: 100%;
  background-color: #ffc937;
  color: #000000;
  padding: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-height: 40px;
}

.uat-banner__text {
  text-align: center;
}

.custom-label-input-antd > label {
  color: #070550 !important;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .ant-btn.ant-btn-primary.ant-btn-color-primary.ant-btn-variant-solid.ant-btn-compact-item.ant-btn-compact-first-item {
    width: calc(
      100% - 30px
    ) !important; /* Add !important to ensure it overrides */
  }
}

.ant-form-item-explain-error {
  font-family: 'Objective';
}

.ant-modal-content.custom-modal-settings-content {
  padding: 0;
}

.custom-label-input-antd > label {
  font-weight: 600 !important;
}

/* .ant-avatar-group > *:not(:first-child) {
  margin-inline-start: -16px;
} */
